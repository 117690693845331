import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "../lib/theme";
import Header from "./Header";
import { useAuth } from "../../shared/useAuth";
import Home from "../Home/Home";
import Checkout from "../Checkout/Checkout";
import Dashboard from "../Dashboard/Dashboard";
import Questionnaire from "../Questionnaire/Questionnaire";
import Welcome from "../Welcome/Welcome";
import User from "../User/User";
import Login from "../Login/Login";
import GetStarted from "../GetStarted/GetStarted";
import Album from "../Album/Album";
import Track from "../Track/Track";
import Register from "../Register/Register";
import { createAndRenderApp } from "../AppWrapper/AppWrapper";
import { getUserData } from "../../shared/firebase";
import Profile from "../Profile/Profile";
import VerifyEmail from "../VerifyEmail/VerifyEmail";

const detectCountry = async () => {
  // Check if country is already stored in localStorage
  const storedCountry = localStorage.getItem('userCountry');
  if (storedCountry) return;

  // Check browser language
  const language = navigator.language || navigator.userLanguage;
  if (language.toLowerCase().includes('en-gb')) {
    localStorage.setItem('userCountry', 'UK');
    return;
  }

  // If not clearly UK, use geolocation API
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    const country = data.country_code === 'GB' ? 'UK' : 'US';
    localStorage.setItem('userCountry', country);
  } catch (error) {
    console.error('Error detecting country:', error);
    localStorage.setItem('userCountry', 'US'); // Default to US if detection fails
  }
};

const App = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const data = await getUserData(user.uid);
        setUserData(data);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    detectCountry();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div>
          {user && <Header user={user} userData={userData} />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/questionnaire" element={<Questionnaire />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/album/:uuid/get-started/:userId" element={<GetStarted />} />
            <Route path="/album/:uuid/" element={<Album />} />
            <Route path="/album/:album_id/track/:uuid" element={<Track />} />
            <Route path="/user/:uuid" element={<User />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<Dashboard user={user} userData={userData} />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default createAndRenderApp(App);
